import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

interface NavItem {
  icon: React.ElementType;
  text: string;
  subnav?: { text: string; href: string }[];
}

interface NavbarProps {
  nav: NavItem[];
}

const Navbar: React.FC<NavbarProps> = ({ nav }) => {
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <nav className="bg-white shadow"> 
      <div className=" mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex justify-between h-16">
          <div className="flex justify-around items-center">
            <div className="grid items-center">
              {/* Add your logo here */}
              <h1 className="text-xl text-atrRed font-semibold tracking-wider ">MARS</h1>
                        <small className='text-gray-700'>Accocunting System</small>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              {nav.map((item) => (
                <div key={item.text} className="relative">
                  <button
                    className="inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    onClick={() => setOpenDropdown(openDropdown === item.text ? null : item.text)}
                  >
                    <item.icon className="h-5 w-5 mr-1" />
                    {item.text}
                    {item.subnav && <ChevronDownIcon className="ml-1" style={{ fontSize: '1rem' }} />}
                  </button>
                  {item.subnav && openDropdown === item.text && (
                    <div className="absolute z-10 left-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {item.subnav.map((subItem) => (
                          <Link
                            key={subItem.text}
                            to={subItem.href}
                            className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            role="menuitem"
                          >
                            {subItem.text}
                          </Link>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="hidden sm:ml-6 sm:flex sm:items-center">
            {/* Add user menu or other elements here if needed */}
          </div>
          <div className="-mr-2 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              aria-controls="mobile-menu"
              aria-expanded="false"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? (
                <CloseIcon className="block h-6 w-6" />
              ) : (
                <MenuIcon className="block h-6 w-6" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu state */}
      <div className={`sm:hidden ${mobileMenuOpen ? 'block' : 'hidden'}`} id="mobile-menu">
        <div className="pt-2 pb-3 space-y-1">
          {nav.map((item) => (
            <div key={item.text}>
              <button
                className="block pl-3 pr-4 py-2 border-l-4 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 w-full text-left"
                onClick={() => setOpenDropdown(openDropdown === item.text ? null : item.text)}
              >
                <div className="flex items-center">
                  <item.icon className="h-5 w-5 mr-1" />
                  {item.text}
                  {item.subnav && <ChevronDownIcon className="ml-auto" style={{ fontSize: '1.25rem' }} />}
                </div>
              </button>
              {item.subnav && openDropdown === item.text && (
                <div className="bg-gray-50 border-l-4 border-transparent">
                  {item.subnav.map((subItem) => (
                    <Link
                      key={subItem.text}
                      to={subItem.href}
                      className="block pl-8 pr-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                    >
                      {subItem.text}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

