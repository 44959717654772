import React from "react";
import { TextField, Grid, MenuItem, IconButton, Button } from "@mui/material";
import { PaymentAccount } from "pages/Accounting/types/invoice";
import { useFormContext } from "pages/Accounting/contexts/FormContext";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/solid";
import { Add } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment, { Moment } from "moment";
interface PaymentsFormProps {
  paymentAccounts: PaymentAccount[];
}

export const PaymentsForm: React.FC<PaymentsFormProps> = ({ paymentAccounts }) => {
  const { formData, updateFormData } = useFormContext();
console.log(formData)
  const handlePaymentChange = (index: number, field: string, value: any) => {
    const updatedPayments = [...(formData.payments || [])];

    // Initialize payment object if not already present
    if (!updatedPayments[index]) {
      updatedPayments[index] = {
        id: index + 1,
        paymentAccount: paymentAccounts[0],
        date: moment(),
        amount: 0,
      };
    }

    if (field === "paymentAccount") {
      updatedPayments[index] = {
        ...updatedPayments[index],
        paymentAccount: value,
      };
    } else {
      updatedPayments[index] = {
        ...updatedPayments[index],
        [field]: value,
      };
    }

    updateFormData({
      payments: updatedPayments,
    });
  };

  const addNewPayment = () => {
    const newPayment = {
      id: (formData.payments?.length || 0) + 1,
      paymentAccount: paymentAccounts[0],
      date: moment(),
      amount: 0,
    };

    updateFormData({
      payments: [...(formData.payments || []), newPayment],
    });
  };

  const removePayment = (index: number) => {
    const updatedPayments = formData.payments.filter((_, idx) => idx !== index);
    updateFormData({
      payments: updatedPayments,
    });
  };

  return (
    <div className=" grid space-y-4">
      {(formData.payments || []).map((payment, index) => (
        <Grid container spacing={2} key={index} className="items-center">
          {/* Payment Account */}
          <Grid item xs={12} sm={3}>
            <TextField
              select
              fullWidth
              label="Payment Account"
              value={payment.paymentAccount?.id || ""}
              onChange={(e) => {
                const selectedAccount = paymentAccounts.find(
                  (acc) => acc.id === parseInt(e.target.value, 10)
                );
                if (selectedAccount) {
                  handlePaymentChange(index, "paymentAccount", selectedAccount);
                }
              }}
              required
              variant="outlined"
            >
              {paymentAccounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {/* Payment Date */}
          <Grid item xs={12} sm={3}>
            <DatePicker
              value={moment(payment.date)} // Ensure moment object is used
              onChange={(date: Moment | null) => handlePaymentChange(index, "date", date || moment())}
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                },
              }}
              minDate={moment().subtract(1,"year")}
              maxDate={moment().add(1, "year")}
            />
          </Grid>
          {/* Payment Amount */}
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={payment.amount || ""}
              onChange={(e) =>
                handlePaymentChange(index, "amount", parseFloat(e.target.value) || 0)
              }
              InputProps={{
                inputProps: {
                  min: 0,
                  step: 0.01,
                },
              }}
              required
              variant="outlined"
            />
          </Grid>

          {/* Remove Payment */}
          <Grid item xs={12} sm={3}>
            <IconButton onClick={() => removePayment(index)} color="error" className="mt-2">
              <TrashIcon className="h-5 w-5" />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Add New Payment */}
      <div className="flex justify-start mt-4">
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addNewPayment}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Payment
      </Button>
    
      </div>
    </div>
  );
};

export default PaymentsForm;
