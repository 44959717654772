import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import Navbar from './components/Navbar';
import { Container } from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}

const navigation = [
  {
    icon: DashboardIcon,
    text: 'Home',
    subnav: [
      { text: 'Overview', href: '/accounting' },
    ],
  },
  {
    icon: ReceiptIcon,
    text: 'Invoices',
    subnav: [
      { text: 'Create New', href: '/accounting/invoices/createnew' },
      { text: 'Invoice List', href: '/accounting/invoices' },
    ],
  },
  {
    icon: AccountBalanceWalletIcon,
    text: 'Wallet',
    subnav: [
      { text: 'Wallet', href: '/accounting/walletnew' },
    ],
  },
  {
    icon: SwapHorizIcon,
    text: 'Transactions',
    subnav: [
      { text: 'Transactions', href: '/transactions' },
    ],
  },
];

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container maxWidth="xl">

      <Navbar nav={navigation} />
        {children}
    </Container>
  );
};

export default Layout;

