import type React from "react"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import type { IDateRange } from "api/types"
import type { FocusedInputShape } from "react-dates"
import TextField from "@mui/material/TextField"
import moment, { Moment } from "moment"
import { Button, MenuItem } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

export interface FiltersState {
  dateRange: IDateRange
  invoiceNumber: string
  bookingReference: string
  productType: string
  outstandingBalance: string
  totalSalesPrice: string
}

interface InvoiceFiltersProps {
  filters: FiltersState
  onFilterChange: (name: string, value: any) => void
  onFilterSubmit: () => void
  datePickerIsOpen?: (isOpen: boolean) => void
}

export function InvoiceFilters({ filters, onFilterChange, onFilterSubmit, datePickerIsOpen }: InvoiceFiltersProps) {
  const [onFocusChange, setFocusedInput] = useState<FocusedInputShape | null>(null)
  const { t } = useTranslation()
  const PRODUCT_TYPES = ["FLIGHT", "RENTALCAR", "HOTEL", "GENERIC", "TRANSFER"] as const

  useEffect(() => {
    if (typeof datePickerIsOpen !== "undefined") datePickerIsOpen(onFocusChange !== null)
  }, [onFocusChange, datePickerIsOpen])


  const handleStartDateChange = (date: moment.Moment | null) => {
    onFilterChange("dateRange", { ...filters.dateRange, startDate: date })
  }

  const handleEndDateChange = (date: moment.Moment | null) => {
    onFilterChange("dateRange", { ...filters.dateRange, endDate: date })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(e.target.name, e.target.value)
  }

  return (
    <div className="mb-6 space-y-4">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-10 grid grid-cols-6 gap-4">
          <div className="col-span-1 pr-2">
              <DatePicker
                value={moment(filters.dateRange.startDate)}
                onChange={(date: Moment | null) => handleStartDateChange(date)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
                format="DD.MM.YYYY"
                minDate={moment().subtract(5, "year")}
                maxDate={moment().add(5, "year")}
              />
          </div>
          <div className="col-span-1 pr-2">
              <DatePicker
                value={moment(filters.dateRange.endDate)}
                onChange={(date: Moment | null) => handleEndDateChange(date)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    variant: "outlined",
                  },
                }}
                format="DD.MM.YYYY"
                minDate={moment().subtract(5, "year")}
                maxDate={moment().add(5, "year")}
              />
          </div>
          <div className="col-span-1">
            <TextField
              label={t("invoiceNumber")}
              name="invoiceNumber"
              value={filters.invoiceNumber}
              onChange={handleInputChange}
              size="small"
              fullWidth
            />
          </div>
          <div className="col-span-1">
            <TextField
              label={t("bookingReference")}
              name="bookingReference"
              value={filters.bookingReference}
              onChange={handleInputChange}
              size="small"
              fullWidth
            />
          </div>
          <div className="col-span-1">
            <TextField
            select
              label={t("productType")}
              name="productType"
              value={filters.productType}
              onChange={handleInputChange}
              size="small"
              fullWidth
            >
              {PRODUCT_TYPES.map((product) => (
              <MenuItem key={product} value={product}>
                {product.toLowerCase()}
              </MenuItem>
            ))}
            </TextField>
          </div>
          <div className="col-span-1">
          <TextField
            label={t("totalSalesPrice")}
            name="totalSalesPrice"
            type="number"
            value={filters.totalSalesPrice}
            onChange={handleInputChange}
            size="small"
              className="pr-2"
          />
          </div>
        </div>
        <div className="col-span-2 flex items-center justify-end">
          <button
            onClick={onFilterSubmit}
            className="px-4 py-2 text-white bg-primary-6000 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 rounded"
          >
            {t("filter")}
          </button>
        </div>
      </div>
    </div>
  )
}

