import React, { createContext, useContext, useState, ReactNode } from 'react';
import moment from 'moment';
import { PaymentAccount, Payments } from '../types/invoice';

export type ProductType = "FLIGHT" | "RENTALCAR" | "HOTEL" | "GENERIC" | "TRANSFER"

interface Passenger {
  id: number;
  paxType: 'ADT' | 'CHD' | 'INF';
  gender: 'MALE' | 'FEMALE';
  name: string;
  surname: string;
  ticketNumber: string;
  tariff: string;
  serviceFee: number;
  passengerProductType:'FLIGHT' | 'RENTALCAR' | 'HOTEL' | 'GENERIC' | 'TRANSFER';
}

interface Flight {
  id: number;
  airline: string;
  flightNumber: string;
  class: 'Economy' | 'Business' | 'First';
  date: moment.Moment;
  from: string;
  to: string;
  baggage: string;
  stopover: string;
  departureTime: string;
  arrivalTime: string;
}

 interface CarRental {
  id: number;
  carType: string;
  pickupDate: moment.Moment;
  returnDate: moment.Moment;
  pickupLocation: string;
  returnLocation: string;
  dailyRate: number;
}

interface HotelBooking {
  id: number;
  hotelName: string;
  checkInDate: moment.Moment;
  checkOutDate: moment.Moment;
  roomType: string;
  numberOfGuests: number;
  pricePerNight: number;
}
interface GenericProduct {
  id: number;
  name: string;
  quantity: number;
  description: string;
  price: number;
}
interface FormData {
  invoiceDetails: {
    customerId: string;
    customerName: string;
    productSupplierId: string;
    paymentAccountId: string;
    productRefId: string;
    productTypes: ('FLIGHT' | 'RENTALCAR' | 'HOTEL' | 'GENERIC' | 'TRANSFER')[];
    invoiceType: 'regular' | 'refund';
    invoiceStatus:'DRAFT' | 'INVOICED'
    isJupiterBooking: boolean,
    invoiceDueDate: moment.Moment | null
  };
  allPassengers: {
    [key in ProductType]: {
      [productId: number]: Passenger[]
    }
  }
  flights: Flight[];
  carRentals: CarRental[];
  hotelBookings: HotelBooking[];
  genericProducts: GenericProduct[];
  payments:Payments[]
}

interface FormContextType {
  formData: FormData;
  updateFormData: (newData: Partial<FormData>) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({
    invoiceDetails: {
      customerId: '',
      customerName: '',
      productSupplierId: '',
      paymentAccountId: '',
      productRefId: '',
      productTypes: ['FLIGHT'],
      invoiceType: 'regular',
      invoiceStatus: 'DRAFT',
      isJupiterBooking: false,
      invoiceDueDate: moment()
    },
    allPassengers: {
      FLIGHT: [],
      RENTALCAR: [],
      HOTEL: [],
      GENERIC: [],
      TRANSFER: [],
    },
    flights: [],
    carRentals: [],
    hotelBookings: [],
    genericProducts: [],
    payments: [],
  });

  const updateFormData = (newData: Partial<FormData>) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};


export const useFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

export {  type FormData, type Passenger, type Flight, type CarRental, type HotelBooking ,type GenericProduct };

