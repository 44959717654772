import React from 'react';
import { 
  TextField, 
  Button,
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import moment from 'moment';
import { DatePickermui } from './DatePickermui';
import { CarRental, useFormContext } from 'pages/Accounting/contexts/FormContext';
import PaxFormComponent from './PaxFormComponent';


const CarRentalForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addCarRental = () => {
    const newCarRentalId = formData.carRentals.length > 0 
      ? Math.max(...formData.carRentals.map(c => c.id)) + 1 
      : 1;
    
    updateFormData({
      carRentals: [
        ...formData.carRentals,
        {
          id: newCarRentalId,
          carType: '',
          pickupDate: moment(),
          returnDate: moment().add(1, 'day'),
          pickupLocation: '',
          returnLocation: '',
          dailyRate: 0,
        }
      ]
    });
  };

  const removeCarRental = (id: number) => {
    updateFormData({
      carRentals: formData.carRentals.filter(c => c.id !== id)
    });
  };

  const updateCarRental = (id: number, field: keyof CarRental, value: any) => {
    updateFormData({
      carRentals: formData.carRentals.map(c => 
        c.id === id ? {...c, [field]: value} : c
      )
    });
  };

  return (
    <div>
      {formData.carRentals.map((carRental, index) => (
        <div key={carRental.id} className="p-4 my-2 border rounded-lg bg-light relative">
          <h2 className="mb-4">
            Car Rental {index + 1}
          </h2>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Car Type"
                value={carRental.carType}
                onChange={(e) => updateCarRental(carRental.id, 'carType', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DatePickermui
                date={carRental.pickupDate}
                handleDateChange={(date) => updateCarRental(carRental.id, 'pickupDate', date)}
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DatePickermui
                date={carRental.returnDate}
                handleDateChange={(date) => updateCarRental(carRental.id, 'returnDate', date)}
                className="w-full"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Pickup Location"
                value={carRental.pickupLocation}
                onChange={(e) => updateCarRental(carRental.id, 'pickupLocation', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Return Location"
                value={carRental.returnLocation}
                onChange={(e) => updateCarRental(carRental.id, 'returnLocation', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Daily Rate"
                type="number"
                value={carRental.dailyRate}
                onChange={(e) => updateCarRental(carRental.id, 'dailyRate', Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
          {formData.carRentals.length > 1 && (
            <IconButton 
              onClick={() => removeCarRental(carRental.id)}
              className="absolute top-2 right-2"
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
                    <PaxFormComponent productType='RENTALCAR' productId={carRental.id} />

        </div>
      ))}
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addCarRental}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Car Rental
      </Button>
    </div>
  );
};

export default CarRentalForm;

