import type React from "react"
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  IconButton,
  Grid,
  Typography,
} from "@mui/material"
import { Delete, Add } from "@mui/icons-material"
import { Passenger, ProductType, useFormContext } from "pages/Accounting/contexts/FormContext"

interface IPassengerForm {
  productType: ProductType
  productId: number
}

const PaxFormComponent: React.FC<IPassengerForm> = ({ productType, productId }) => {
  const { formData, updateFormData } = useFormContext()

  const getPassengers = (): Passenger[] => {
    return formData.allPassengers[productType][productId] || []
  }

  const addPassenger = () => {
    const passengers = getPassengers()
    const newPassengerId = passengers.length > 0 ? Math.max(...passengers.map((p) => p.id)) + 1 : 1

    const newPassenger: Passenger = {
      id: newPassengerId,
      paxType: "ADT",
      gender: "MALE",
      name: "",
      surname: "",
      ticketNumber: "",
      tariff: "",
      serviceFee: 0,
      passengerProductType: productType,
    }

    updateFormData({
      allPassengers: {
        ...formData.allPassengers,
        [productType]: {
          ...formData.allPassengers[productType],
          [productId]: [...passengers, newPassenger],
        },
      },
    })
  }

  const removePassenger = (id: number) => {
    updateFormData({
      allPassengers: {
        ...formData.allPassengers,
        [productType]: {
          ...formData.allPassengers[productType],
          [productId]: getPassengers().filter((p) => p.id !== id),
        },
      },
    })
  }

  const updatePassenger = (id: number, field: keyof Passenger, value: string | number) => {
    updateFormData({
      allPassengers: {
        ...formData.allPassengers,
        [productType]: {
          ...formData.allPassengers[productType],
          [productId]: getPassengers().map((p) => (p.id === id ? { ...p, [field]: value } : p)),
        },
      },
    })
  }

  const passengers = getPassengers()

  return (
    <div className="space-y-6">
      {passengers.map((passenger, index) => (
        <div key={passenger.id} className="bg-gray-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-4">
            <Typography variant="subtitle1" className="text-gray-700">
              Passenger {index + 1}
            </Typography>
            {passengers.length > 1 && (
              <IconButton onClick={() => removePassenger(passenger.id)} color="error" size="small">
                <Delete />
              </IconButton>
            )}
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Passenger Type</InputLabel>
                <Select
                  value={passenger.paxType}
                  label="Passenger Type"
                  onChange={(e) => updatePassenger(passenger.id, "paxType", e.target.value as "ADT" | "CHD" | "INF")}
                >
                  <MenuItem value="ADT">Adult</MenuItem>
                  <MenuItem value="CHD">Child</MenuItem>
                  <MenuItem value="INF">Infant</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={passenger.gender}
                  label="Gender"
                  onChange={(e) => updatePassenger(passenger.id, "gender", e.target.value as "MALE" | "FEMALE")}
                >
                  <MenuItem value="MALE">Male</MenuItem>
                  <MenuItem value="FEMALE">Female</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Name"
                value={passenger.name}
                onChange={(e) => updatePassenger(passenger.id, "name", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                label="Surname"
                value={passenger.surname}
                onChange={(e) => updatePassenger(passenger.id, "surname", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Ticket Number"
                value={passenger.ticketNumber}
                onChange={(e) => updatePassenger(passenger.id, "ticketNumber", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Tariff"
                value={passenger.tariff}
                onChange={(e) => updatePassenger(passenger.id, "tariff", e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Service Fee"
                type="number"
                value={passenger.serviceFee}
                onChange={(e) => updatePassenger(passenger.id, "serviceFee", Number(e.target.value))}
                fullWidth
              />
            </Grid>
          </Grid>
        </div>
      ))}
      <Button variant="outlined" color="primary" onClick={addPassenger} startIcon={<Add />} className="mt-4">
        Add Passenger
      </Button>
    </div>
  )
}

export default PaxFormComponent

