import React from "react";
import { useReduxReducer } from "redux/store";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import moment from "moment";

const AtrFooter: React.FC = () => {
  const { footer } = useReduxReducer((state) => state.config);
  const {t} = useTranslation()
  return (
    <div className="nc-Footer relative border-t border-neutral-200 dark:border-neutral-700 py-2 font-light text-base text-center">
        <p>{footer.linksEnabled ? moment().year() + " © JUPITER by A.T.R. Touristik Service GmbH" : footer.title}</p>
        {footer.linksEnabled 
        ? <p className="text-blue-500">
            <a className="cursor-pointer" target = "_blank" href = "https://www.atrtouristik.com/public/imprint">{t("homepage.imprint")}</a>
            &nbsp;&nbsp;&nbsp;
            <a className="cursor-pointer" target = "_blank" href = "https://www.atrtouristik.com/public/privacy-policy">Datenschutz</a>
            &nbsp;&nbsp;&nbsp;
            <a className="cursor-pointer" target = "_blank" href = "https://www.atrtouristik.com/resources/docs/Erweiterte_AGB.pdf">Erweiterte AGB</a>
        </p>
        :  <p className="text-blue-500">
        <Link className="cursor-pointer" to="/imprint">{t("homepage.imprint")}</Link>
        &nbsp;&nbsp;&nbsp;
        <Link className="cursor-pointer" to="/privacypolicy">{t("homepage.privacy-policy")}</Link>
        &nbsp;&nbsp;&nbsp;
        <Link className="cursor-pointer" to="/aboutus">{t("homepage.about-us")}</Link>
    </p>}
    </div>
  );
};

export default AtrFooter;
