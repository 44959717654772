import React from 'react';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TextField } from '@mui/material';
import moment from 'moment';

interface DatePickerProps {
  date: moment.Moment;
  handleDateChange: (date: moment.Moment | null) => void;
  className?: string;
  error?: string;
}

export const DatePickermui: React.FC<DatePickerProps> = ({
  date,
  handleDateChange,
  className = '',
  error = '',
}) => {
  return (
    <div className={className}> <LocalizationProvider dateAdapter={AdapterMoment}>
    <MuiDatePicker
      value={date}
      onChange={(newDate) => handleDateChange(newDate)}
      format="DD.MM.YYYY"
      className={className}
      slots={{
        textField: (params) => (
          <TextField
            {...params}
            fullWidth
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              placeholder: "DD.MM.YYYY"
            }}
          />
        ),
      }}
    />
  </LocalizationProvider></div>
   
  );
};

