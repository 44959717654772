import React, { useState } from 'react';
import Layout from "../layout";
import { Button, Card, CardContent, CardHeader, Typography, Container, Box, FormControlLabel, Switch } from '@mui/material';

import { FormProvider, useFormContext } from '../contexts/FormContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CarRentalForm from './components/CarRentalForm';
import FlightInformationForm from './components/FlightInformationForm';
import { InvoiceForm } from './components/InvoiceForm';
import PaxFormComponent from './components/PaxFormComponent';
import HotelBookingForm from './components/HotelBookingForm';
import GenericProductForm from './components/GenericFormComponent';
import { SaveOrUpdateInvoiceData } from '../types/invoice';
import postFetcher from 'api/helpers/postFetcher';

const CreateInvoicesContent: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const { formData, updateFormData } = useFormContext();
  
  const steps = ['Invoice Details', 'Product Information', 'Passenger Information', 'Draft Summary'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const transformFormData = () => {
      const flightBookings = formData.invoiceDetails.productTypes.includes("FLIGHT")
        ? formData.flights.map((flight) => ({
            id: flight.id,
            supplier: {
              id: Number(formData.invoiceDetails.productSupplierId),
              invoiceOwnerAgencyId: Number(formData.invoiceDetails.customerId),
              supplierName: formData.invoiceDetails.customerName,
              notes: "",
            },
            flightList: [
              {
                id: flight.id,
                flightNumber: flight.flightNumber,
                airline: flight.airline.toUpperCase(),
                classOfService: flight.class,
                baggageAllowance: flight.baggage,
                departureDate: flight.date,
                arrivalDate: flight.date, // Assuming arrival date is the same as departure date
                departurePort: flight.from,
                arrivalPort: flight.to,
                stopoverPort: flight.stopover || "",
                cabinClass: flight.class.toUpperCase(),
              },
            ],
            paxList: (formData.allPassengers.FLIGHT[flight.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
              ticketnumber: passenger.ticketNumber,
              baseFare: Number.parseFloat(passenger.tariff),
              tax: 0, // Placeholder, update if you have actual tax
              serviceFee: passenger.serviceFee,
              hiddenServiceFee: 0, // Placeholder, update if you have actual hidden service fee
            })),
          }))
        : []
    
      const hotelBookings = formData.invoiceDetails.productTypes.includes("HOTEL")
        ? formData.hotelBookings.map((hotel) => ({
            id: hotel.id,
            hotelName: hotel.hotelName,
            checkInDate: hotel.checkInDate,
            checkOutDate: hotel.checkOutDate,
            paxList: (formData.allPassengers.HOTEL[hotel.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
            })),
            // ... other hotel booking fields
          }))
        : []
    
      const rentalcarBookings = formData.invoiceDetails.productTypes.includes("RENTALCAR")
        ? formData.carRentals.map((car) => ({
            id: car.id,
            carType: car.carType,
            pickupDate: car.pickupDate,
            returnDate: car.returnDate,
            paxList: (formData.allPassengers.RENTALCAR[car.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
            })),
            // ... other car rental fields
          }))
        : []
    
      const genericProducts = formData.invoiceDetails.productTypes.includes("GENERIC")
        ? formData.genericProducts.map((product) => ({
            id: product.id,
            productName: product.name,
            description: product.description,
            paxList: (formData.allPassengers.GENERIC[product.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
            })),
            // ... other generic product fields
          }))
        : []
    
      return {
        id: null,
        bookingReference: formData.invoiceDetails.productRefId,
        jupiterBooking: formData.invoiceDetails.isJupiterBooking,
        flightBookings,
        hotelBookings,
        rentalcarBookings,
        transferBookings: [], // Assuming no transfer bookings in the current form
        genericProducts,
        payments: formData.payments.map((payment) => ({
          id: payment.id,
          paymentAccountId: payment.paymentAccount.id,
          date: payment.date,
          amount: payment.amount,
        })),
        invoiceDueDate: formData.invoiceDetails.invoiceDueDate?.toISOString() || null,
        invoiceStatus: formData.invoiceDetails.invoiceStatus,
        paid: "", // Update this if you have a paid status in your form
        customerUserId: Number(formData.invoiceDetails.customerId),
      }
    }
    
    
  
    const invoiceCreateForm = transformFormData()
    console.log(invoiceCreateForm)
  
    // try {
    //   const response = await postFetcher("api/mars/invoices/saveOrUpdate", invoiceCreateForm)
  
    //   if (!response.ok) {
    //     throw new Error(`HTTP error! Status: ${response.status}`)
    //   }
    //   const data = await response.json()
    //   console.log(data)
    //   return data
    // } catch (error) {
    //   console.error("Error submitting form:", error)
    //   throw error
    // }
  }
  
  const renderProductForm = () => {
    return (
      <>
        {formData.invoiceDetails.productTypes.map((product) => {
          if (product === 'FLIGHT') return <FlightInformationForm key={product} />;
          if (product === 'RENTALCAR') return <CarRentalForm key={product} />;
          if (product === 'HOTEL') return <HotelBookingForm key={product} />;
          if (product === 'GENERIC') return <GenericProductForm key={product} />;
          return null; // Handle unexpected cases gracefully
        })}
      </>
    );
  };
  

  return (
    <Container maxWidth="xl">
    <Box sx={{ my: 2 }}>
    <h1 className="text-3xl font-bold my-4">Create Invoice</h1>
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Invoice Details" />
        <CardContent>
        <FormControlLabel
          control={
            <Switch
              checked={formData.invoiceDetails.isJupiterBooking}
              onChange={(event) => {
                updateFormData({
                  invoiceDetails: {
                    ...formData.invoiceDetails,
                    isJupiterBooking: event.target.checked,
                  },
                });
              }}
              name="isJupiterBooking"
              color="primary"
            />
          }
          label="Jupiter Booking"
        />
          <InvoiceForm />
        </CardContent>
      </Card>
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Product Information" />
        <CardContent>
          {renderProductForm()}

        </CardContent>
      </Card>
      <Card sx={{ mb: 4 }}>
      </Card>
{/*
      <Card sx={{ mb: 4 }}>
        <CardHeader title="Draft Summary" />
        <CardContent>
          <InvoiceDraftSummary />
        </CardContent>
      </Card>*/}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Submit
        </Button>
      </Box>
    </Box>
  </Container>

  );
};

const CreateInvoices: React.FC = () => {
  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
          <CreateInvoicesContent />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};

export default CreateInvoices;

