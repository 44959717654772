import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormProvider } from "../contexts/FormContext";
import Layout from "../layout";
import InvoiceTable from "./components/InvoiceTable";
import { useReduxReducer } from "redux/store";

const Invoices: React.FC = () => {
  const {isAdmin} = useReduxReducer(state => state.general)
  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
        <h1 className="text-3xl font-bold my-4">Invoices</h1>
{isAdmin ?           <InvoiceTable />
: "yetki yok"}
          <InvoiceTable />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};
export default Invoices;
