import React, { useEffect, useState } from "react";
import {
  HashRouter,
  Routes as Switch,
  Route,
  useLocation,
  useParams,
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import PageHome from "pages/PageHome";
import Page404 from "pages/Page404";
import SiteHeader from "pages/SiteHeader";
import FlightCheckout from "../oldFlights/components/flights/FlightCheckout";
import BookingDetail from "pages/BookingDetail/BookingDetail";
import AtrFooter from "../components/ui/AtrFooter/Footer";
import {InvoicePage} from "../pages/InvoiceData/InvoicePage";
import {SalesreportPage} from "../pages/SalesreportPage/SalesreportPage";
import PackageTourSearch from "pages/PackageTour";
import {useDispatch} from "react-redux";
import {setIsAdmin, setIsApp, setIsB2C, setIsGuest, setIsMobile, setUserData} from "../redux/slices/generalSlice";
import {useReduxReducer} from "../redux/store";
import fetcher from "api/helpers/fetcher";
import {b2cURL, getCheckLoggedInLink, getConfigUrl, getHost, getUserDataUrl} from "api/helpers/api-constants";
import { setConfig } from "redux/slices/configSlice";
import { Crisp } from "crisp-sdk-web";
import {MobileBookingDetail} from "../pages/BookingDetail/MobileBookingDetail";
import { Helmet } from "react-helmet";
import CustomerSupport from "pages/CustomerSupport";
import BookingDetailNew from "pages/BookingDetail/BookingDetailNew/BookingDetailNew";
import ReloadPopup from "pages/PageHome/ReloadPopup";
import { setServiceFee } from "redux/slices/flightSlice";
import BookingApproval from "pages/BookingApprovalPage/BookingApproval";
import Wallet from "pages/Wallet";
import WalletMain from "pages/Wallet/components/WalletMain";
import InsuranceInfo from "pages/PageHome/StaticPages/InsuranceInfo";
import Invoices from "pages/Accounting/Invoices/invoices";
import CreateInvoices from "pages/Accounting/Invoices/CreateInvoice";
import WalletNew from "pages/Accounting/Wallets";

const getHome = () => {
  return PageHome;
};


  


const FlightCheckoutWrap = () => {

  const params = useParams();
  const location = useLocation();

  return <FlightCheckout params={params} location={location} />;
};

const HomePage = React.lazy(() => import('../pages/PageHome'))
const FlightSearchPage = React.lazy(() => import('../pages/FlightSearchPage'))
const FlightSearchPageB2C = React.lazy(() => import('../pages/FlightSearchPage/FlightSearchB2c'))

const FlightCheckOutPage = React.lazy(() => import('../pages/FlightCheckOutPage'))
const HotelDetailPage = React.lazy(() => import('../pages/HotelDetailPage/HotelDetailPage'))
const HolidaysSearchResultsPage = React.lazy(() => import('../pages/HolidaySearchResultsPage'))
const TravelData = React.lazy(() => import('../pages/TravelData'))
const RentCarResults = React.lazy(() => import('../pages/RentCarResults'))
const TransferResults = React.lazy(() => import('../pages/TransferResults/index'))
const SearchMapPage = React.lazy(() => import('../pages/SearchMapPage'))
const ListingStayDetailPage = React.lazy(() => import('../pages/ListingDetailPage/ListingStayDetailPage'))
const CheckOutPage = React.lazy(() => import('../pages/CheckOutPage'))
const TourSearchPage = React.lazy(() => import('../pages/TourSearchPage'))
const ListingTourDetailPage = React.lazy(() => import('../pages/ListingDetailPage/ListingTourDetailPage'))
const TourCheckOutPage = React.lazy(() => import('../pages/TourCheckOutPage'))
const BookingListPage = React.lazy(() => import('../pages/BookingListPage/BookingListPage'))
const BookingList = React.lazy(() => import('../pages/BookingListPage/mobile/BookingList'))
const PackageTourHotel = React.lazy(() => import('../pages/PackageTour/PackageTourHotel'))
const PackageTourBooking = React.lazy(() => import('../pages/PackageTour/PackageTourBooking'))
const AboutUs = React.lazy(() => import('../pages/B2CFooter/AboutUs'))
const DataProtection = React.lazy(() => import('../pages/B2CFooter/DataProtection'))
const Imprint = React.lazy(() => import('../pages/B2CFooter/PageImprint'))
const Dashboard = React.lazy(() => import('../pages/B2BDashboard') )
const ApproachingFlightsMainPage = React.lazy(() => import('../pages/B2BDashboard/components/ApproachingFlightsMainPage'))
const QRCodePage = React.lazy(() => import('../pages/PageHome/QRCodePage') )
const Accounting = React.lazy(() => import('../pages/Accounting'))

export const pagesB2B: Page[] = [
  { path: "/", Component: HomePage },
  { path: "/ibe/", Component: HomePage },

  { path: "/holidays-results", Component: HolidaysSearchResultsPage },
  { path: "/travel-data", Component: TravelData },
  { path: "/rent-car/results", Component: RentCarResults },
  { path: "/transfer/results", Component: TransferResults },
  { path: "/search", Component: SearchMapPage },
  { path: "/hotel/:id", Component: ListingStayDetailPage },
  { path: "/hotel-detail/:id", Component: HotelDetailPage },
  { path: "/offer-details/:id", Component: CheckOutPage },
  { path: "/tour-search", Component: TourSearchPage },
  { path: "/tour", Component: ListingTourDetailPage },
  { path: "/touroffer-details", Component: TourCheckOutPage },
  { path: "/flight-search", Component: FlightSearchPage },
  { path: "/flight-offer-details/:id", Component: FlightCheckOutPage },
  { path: "/bookings", Component: BookingListPage },
  { path: "/invoice", Component: InvoicePage },
  { path: "/tourpackage", Component: PackageTourSearch },
  { path: "/tourpackage/hotel", Component: PackageTourHotel},
  {path: "/tourpackage/booking/:detailKey", Component: PackageTourBooking},
  {path: "/salesreport", Component: SalesreportPage},
  {path: "/app/salesreport", Component: SalesreportPage},
  {path: "/customerSupport", Component: CustomerSupport},
  {path: "/booking-approval", Component: BookingApproval},
  {path: "/accounting/wallet", Component: Wallet},
  {path: "/accounting/invoices", Component: Invoices},
  {path: "/accounting", Component: Accounting},
  {path: "/accounting/invoices/createnew", Component: CreateInvoices},

  {path: "/accounting/walletnew", Component: WalletNew},

  {
    path: "/wallet/:agencyNumber",
    Component: WalletMain,
  },

  {
    path: "/flightcheckout",
    Component: FlightCheckoutWrap,
  },
  {
    path: "/booking/detail/:bookingKey",
    Component: BookingDetailNew,
  },
  {
    path: "/booking/detail/test/:bookingKey",
    Component: BookingDetail,
  },
  {
    path: "/app/booking/detail/:bookingKey",
    Component: MobileBookingDetail,
  },

  { path: "/app/:type", Component: HomePage },
  { path: "/app/bookings", Component: BookingList },
  { path: "/insurance", Component: InsuranceInfo },

  {path: "/dashboard", Component: Dashboard},
  {path: "/approachingflights", Component: ApproachingFlightsMainPage },
  {path: "/getapp", Component: QRCodePage},
];
export const pagesB2C: Page[] = [
  { path: "/", Component: HomePage },
  { path: "/ibe/", Component: HomePage },

  { path: "/holidays-results", Component: HolidaysSearchResultsPage },
  { path: "/travel-data", Component: TravelData },
  { path: "/rent-car/results", Component: RentCarResults },
  { path: "/transfer/results", Component: TransferResults },
  { path: "/search", Component: SearchMapPage },
  { path: "/hotel/:id", Component: ListingStayDetailPage },
  { path: "/hotel-detail/:id", Component: HotelDetailPage },
  { path: "/offer-details/:id", Component: CheckOutPage },
  { path: "/tour-search", Component: TourSearchPage },
  { path: "/tour", Component: ListingTourDetailPage },
  { path: "/touroffer-details", Component: TourCheckOutPage },
  { path: "/flight-search", Component: FlightSearchPageB2C },
  { path: "/flight-offer-details/:id", Component: FlightCheckOutPage },
  { path: "/tourpackage", Component: PackageTourSearch },
  { path: "/tourpackage/hotel", Component: PackageTourHotel},
  {path: "/tourpackage/booking/:detailKey", Component: PackageTourBooking},
  {
    path: "/flightcheckout",
    Component: FlightCheckoutWrap,
  },
  {
    path: "/booking/detail/:bookingKey",
    Component: BookingDetail,
  },
  {
    path: "/app/booking/detail/:bookingKey",
    Component: MobileBookingDetail,
  },

  { path: "/app/:type", Component: HomePage },
  {path:"/aboutus", Component: AboutUs},
  {path:"/privacypolicy", Component:DataProtection},
  {path:"/imprint", Component:Imprint},


];
const Routes = () => {
  const dispatch = useDispatch();
  const{ audit } = useReduxReducer(state => state.config)
  const { isGuest, userData } = useReduxReducer(state => state.general)
  const {meta,header} = useReduxReducer((state)  => state.config)

const [showPopup, setshowPopup] = useState<boolean>(false);
const findMainJsPath = () => {
  const scripts = document.getElementsByTagName('script');
  let path = null;  
  for (let i = 0; i < scripts.length; i++) {
    const script = scripts[i];
    const src = script.src;
    const isMainJs = script.defer && src.includes('/static/js/main.') && src.endsWith('.js');
    if (isMainJs) {
      const url = new URL(src);
      path = url.pathname;  
      break;
    }
  }
  return path;  
};

  const checkLoggedIn = async () => {
   
    
    setshowPopup(false)
    try {
      const res = await fetcher(getCheckLoggedInLink(), {
        method: 'GET',
       
      });
      if(res){
        if (audit.marketingType === "B2B") {
          if(!(res.loggedIn)){
            window.location.href = getHost() + '/logout';
          }
        }
        if(res.reactFile === findMainJsPath()){
          setshowPopup(false)
        }
        else {
          process.env.NODE_ENV === "production" &&
          setshowPopup(true)
        }
      }
     
    } catch (error) {

      console.error('Error checking login status:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetcher(`${isGuest ? b2cURL : ''}${getConfigUrl()}`);
        dispatch(setConfig(res));
        dispatch(setIsB2C(res.audit.marketingType === "B2C" ? true : false))
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    };

    fetchData();

  }, [isGuest]);

  const handleVisibilityChange = () => {
    if (!document.hidden && audit.marketingType === "B2B") {
      checkLoggedIn();
    }
  };

  const setupVisibilityChangeHandler = () => {
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  };

  const setupIntervalChecker = () => {
  checkLoggedIn();

      const intervalId = setInterval(() => {
  checkLoggedIn();
    }, 60000);

    return () => clearInterval(intervalId);
  };

  useEffect(() => {
    const cleanupVisibilityChangeHandler = setupVisibilityChangeHandler();
    const cleanupIntervalChecker = setupIntervalChecker();

    return () => {
      cleanupVisibilityChangeHandler();
      cleanupIntervalChecker();
    };
  }, []);

  const { isMobile, isApp } = useReduxReducer(state => state.general)
  async function fetchUserData() {
    try {
      const userDataResponse = await fetcher(getUserDataUrl());
      dispatch(setUserData(userDataResponse))
      if (!userDataResponse?.hasOwnProperty("status") && userDataResponse) {
        if (userDataResponse.userRole === "ADMIN" || userDataResponse.userRole === "SYSTEM_EMPLOYEE") {
          dispatch(setIsAdmin(true));
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }
  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth < 916))
    }

    window.addEventListener('resize', handleResize)

    dispatch(setIsApp(window.location.hash.includes('/app/')))
    dispatch(setIsGuest(window.location.hash.includes('?guest=1')))
    fetchUserData();

  }, [])
console.log(showPopup,findMainJsPath())
 
  const { footer } = useReduxReducer((state) => state.config);
  if (footer.chatEnabled && userData) {
    const websiteId = "3281171c-7669-49a4-b596-b78c65b63adc"
    const tokenId = userData.crispTokenId
    Crisp.user.setEmail(userData!.email);
    Crisp.user.setNickname(userData!.nickname);
    Crisp.user.setCompany("company", {description:userData!.company});
    Crisp.setTokenId(tokenId)
    Crisp.configure(websiteId, {
      autoload: false,
    });
  }
  return (
    <HashRouter>
      <>
      <Helmet>
        <title>
         {meta.title}
        </title>
        <link rel="icon" type="image/png" href={header.favicon} sizes="16x16" />
        <meta name="description" content={meta.description} />
        <meta name="google" content="notranslate"></meta>
        <link rel="apple-touch-icon" href={header.defaultLogo} />


      </Helmet>
        <ScrollToTop />
        <SiteHeader/>
      </>
      <Switch>
       
        <Route path="*" element={<Page404 />} />
        {(audit.marketingType ==="B2C" ? pagesB2C : pagesB2B)
        .map(({ Component, path }) => {

          return <Route key={path} path={path} element={<Component />} />;

        })}
      </Switch>
      {!isApp && <AtrFooter />}
      {!isApp && footer.chatEnabled && userData && Crisp.load()}
      <ReloadPopup showPopup={showPopup} onClose={()=> setshowPopup(false)}/>
    </HashRouter>
  );
};

export default Routes;
