import type React from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  Divider,
} from "@mui/material"
import moment from "moment"
import currencyFormat from "utils/currencyFormat"
import {
  Edit as EditIcon,
  PictureAsPdf as PdfIcon,
  DirectionsCar,
  Hotel,
  TransferWithinAStation,
} from "@mui/icons-material"
import { useNavigate } from "react-router-dom"

interface InvoiceDetailDialogProps {
  open: boolean
  onClose: () => void
  invoiceDetail: any
}

const RentalCarBooking: React.FC<{ booking: any }> = ({ booking }) => (
  <Paper style={{ padding: "10px", marginTop: "10px" }}>
    <Typography variant="subtitle1">Supplier: {booking.supplier.supplierName}</Typography>
    <Typography variant="subtitle1">Vehicle: {booking.vehicle}</Typography>
    <Typography variant="subtitle1">
      Pickup: {moment(booking.pickupDate).format("DD-MM-YYYY HH:mm")} - {booking.pickupLocationName}
    </Typography>
    <Typography variant="subtitle1">
      Return: {moment(booking.returnDate).format("DD-MM-YYYY HH:mm")} - {booking.returnLocationName}
    </Typography>
    <Typography variant="subtitle1">Total Days: {booking.totalDays}</Typography>
    <Typography variant="subtitle1">Daily Rent Price: {currencyFormat(booking.dailyRentPrice)}</Typography>
    <Typography variant="subtitle1">Total Price: {currencyFormat(booking.totalSalesPrice)}</Typography>
  </Paper>
)

const HotelBooking: React.FC<{ booking: any }> = ({ booking }) => (
  <Paper style={{ padding: "10px", marginTop: "10px" }}>
    <Typography variant="subtitle1">Hotel: {booking.hotelName}</Typography>
    <Typography variant="subtitle1">Check-in: {moment(booking.checkInDate).format("DD-MM-YYYY")}</Typography>
    <Typography variant="subtitle1">Check-out: {moment(booking.checkOutDate).format("DD-MM-YYYY")}</Typography>
    <Typography variant="subtitle1">Nights: {booking.nights}</Typography>
    <Typography variant="subtitle1">Room Type: {booking.roomType}</Typography>
    <Typography variant="subtitle1">Total Price: {currencyFormat(booking.totalSalesPrice)}</Typography>
  </Paper>
)

const TransferBooking: React.FC<{ booking: any }> = ({ booking }) => (
  <Card sx={{ margin: "20px", border: "1px solid #ccc", boxShadow: 3 }}>
    <CardContent>
      <Typography variant="h5" gutterBottom>
        Transfer Booking Details
      </Typography>

      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Supplier: {booking.supplier.supplierName}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Booking Reference: {booking.bookingReference}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Pickup Location:</strong> {booking.pickupLocation}
          </Typography>
          <Typography variant="body1">
            <strong>Destination:</strong> {booking.destinationLocation}
          </Typography>
          <Typography variant="body1">
            <strong>Pickup Date:</strong>{" "}
            {moment(booking.pickupDate).format("DD-MM-YYYY HH:mm")}
          </Typography>
          <Typography variant="body1">
            <strong>Return Date:</strong>{" "}
            {moment(booking.returnDate).format("DD-MM-YYYY HH:mm")}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1">
            <strong>Vehicle Type:</strong> {booking.vehicleType}
          </Typography>
          <Typography variant="body1">
            <strong>Flight Number:</strong> {booking.flightNumber} (
            {booking.flightDeparturePort})
          </Typography>
          <Typography variant="body1">
            <strong>Flight Date:</strong> {booking.flightDate}
          </Typography>
          <Typography variant="body1">
            <strong>Total Price:</strong> {booking.totalSalesPrice.toFixed(2)}
          </Typography>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        Passengers
      </Typography>
      <ul style={{ margin: 0, paddingLeft: "20px" }}>
        {booking.passengerList.map((passenger :any, index: number) => (
          <li key={index} style={{ marginBottom: "5px" }}>
            {passenger.firstname} {passenger.surname}
          </li>
        ))}
      </ul>
    </CardContent>
  </Card>
)

const InvoiceDetailDialog: React.FC<InvoiceDetailDialogProps> = ({ open, onClose, invoiceDetail }) => {
  const navigate = useNavigate()

  if (!invoiceDetail) return null

  const handleEdit = () => {
    onClose()
    navigate(`/edit-invoice?invoiceId=${invoiceDetail.invoiceNumber}`)
  }

  const handleShowPdf = () => {
    window.open(`https://www.atrtouristik.com/agency/mars/invoices/${invoiceDetail.invoiceNumber}.pdf`, "_blank")
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
       
        {invoiceDetail.productType === "RENTALCAR" && <DirectionsCar />}
        {invoiceDetail.productType === "HOTEL" && <Hotel />}
        {invoiceDetail.productType === "TRANSFER" && <TransferWithinAStation />}
        Invoice Detail
        <Button
          startIcon={<EditIcon />}
          variant="contained"
          color="primary"
          onClick={handleEdit}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          Edit
        </Button>
        <Button
          startIcon={<PdfIcon />}
          variant="contained"
          color="secondary"
          onClick={handleShowPdf}
          sx={{
            position: "absolute",
            right: 120,
            top: 8,
          }}
        >
          Show PDF
        </Button>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Invoice Number: {invoiceDetail.invoiceNumber}</Typography>
            <Typography variant="subtitle1">Booking Reference: {invoiceDetail.bookingReference}</Typography>
            <Typography variant="subtitle1">Status: {invoiceDetail.invoiceStatus}</Typography>
            <Typography variant="subtitle1">Product Type: {invoiceDetail.productType}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle1">Customer: {invoiceDetail.agencyCustomerName}</Typography>
            <Typography variant="subtitle1">Customer Number: {invoiceDetail.agencyCustomerNumber}</Typography>
            <Typography variant="subtitle1">
              Travel Date: {moment(invoiceDetail.travelDate).format("DD-MM-YYYY")}
            </Typography>
            <Typography variant="subtitle1">Travel Registrant: {invoiceDetail.travelRegistrant}</Typography>
          </Grid>
        </Grid>

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Bookings
        </Typography>
        {invoiceDetail.flightBookings.map((booking: any, index: number) => (
          <Paper key={index} style={{ padding: "10px", marginTop: "10px" }}>
            <Typography variant="subtitle1">Airline: {booking.airline}</Typography>
            <Typography variant="subtitle1">Supplier: {booking.supplier.supplierName}</Typography>

            <Typography variant="h6" style={{ marginTop: "10px" }}>
              Flights
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Flight Number</TableCell>
                    <TableCell>Departure</TableCell>
                    <TableCell>Arrival</TableCell>
                    <TableCell>Class</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {booking.flightList.map((flight: any) => (
                    <TableRow key={flight.id}>
                      <TableCell>{flight.flightNumber}</TableCell>
                      <TableCell>{`${flight.departurePort} (${moment(flight.departureDate).format("DD-MM-YYYY HH:mm")})`}</TableCell>
                      <TableCell>{`${flight.arrivalPort} (${moment(flight.arrivalDate).format("DD-MM-YYYY HH:mm")})`}</TableCell>
                      <TableCell>{flight.cabinClass}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Typography variant="h6" style={{ marginTop: "10px" }}>
              Passengers
            </Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Ticket Number</TableCell>
                    <TableCell>Base Fare</TableCell>
                    <TableCell>Tax</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {booking.paxList.map((pax: any) => (
                    <TableRow key={pax.id}>
                      <TableCell>{`${pax.firstname} ${pax.surname}`}</TableCell>
                      <TableCell>{pax.paxtype}</TableCell>
                      <TableCell>{pax.ticketnumber}</TableCell>
                      <TableCell>{currencyFormat(pax.baseFare)}</TableCell>
                      <TableCell>{currencyFormat(pax.tax)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ))}
        {invoiceDetail.rentalCarBookings.map((booking: any, index: number) => (
          <RentalCarBooking key={index} booking={booking} />
        ))}
        {invoiceDetail.hotelBookings.map((booking: any, index: number) => (
          <HotelBooking key={index} booking={booking} />
        ))}
        {invoiceDetail.transferBookings.map((booking: any, index: number) => (
          <TransferBooking key={index} booking={booking} />
        ))}

        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Pricing
        </Typography>
        <Typography>Total Purchase Price: {currencyFormat(invoiceDetail.totalPurchasePrice)}</Typography>
        <Typography>Total Sales Price: {currencyFormat(invoiceDetail.totalSalesPrice)}</Typography>
        <Typography>Outstanding Balance: {currencyFormat(invoiceDetail.outstandingBalance)}</Typography>
        <Typography>Paid: {invoiceDetail.paid ? "Yes" : "No"}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InvoiceDetailDialog

