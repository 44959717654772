import React from 'react';
import { 
  TextField, 
  Button,
  Grid,
  Paper,
  Typography,
  IconButton
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import { useFormContext } from 'pages/Accounting/contexts/FormContext';
import { DivOverlay } from 'leaflet';
import PaxFormComponent from './PaxFormComponent';

interface GenericProduct {
  id: number;
  name: string;
  quantity: number;
  description: string;
  price: number;
}

const GenericProductForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addGenericProduct = () => {
    const newProductId = formData.genericProducts.length > 0 
      ? Math.max(...formData.genericProducts.map(p => p.id)) + 1 
      : 1;
    
    updateFormData({
      genericProducts: [
        ...formData.genericProducts,
        {
          id: newProductId,
          name: '',
          quantity: 1,
          description: '',
          price: 0,
        }
      ]
    });
  };

  const removeGenericProduct = (id: number) => {
    updateFormData({
      genericProducts: formData.genericProducts.filter(p => p.id !== id)
    });
  };

  const updateGenericProduct = (id: number, field: keyof GenericProduct, value: string | number) => {
    updateFormData({
      genericProducts: formData.genericProducts.map(p => 
        p.id === id ? {...p, [field]: value} : p
      )
    });
  };

  return (
    <div>
      {formData.genericProducts.map((product, index) => (
        <div key={product.id} className="p-4 my-4 relative border rounded-lg bg-light">
          <Typography variant="h6" className="mb-4">
            Product {index + 1}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                label="Product Name"
                value={product.name}
                onChange={(e) => updateGenericProduct(product.id, 'name', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <TextField
                label="Quantity"
                type="number"
                value={product.quantity}
                onChange={(e) => updateGenericProduct(product.id, 'quantity', Number(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Price"
                type="number"
                value={product.price}
                onChange={(e) => updateGenericProduct(product.id, 'price', Number(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Description"
                multiline
                rows={1}
                value={product.description}
                onChange={(e) => updateGenericProduct(product.id, 'description', e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
          <PaxFormComponent productType='GENERIC' productId={product.id} />

          {formData.genericProducts.length > 1 && (
            <IconButton 
              onClick={() => removeGenericProduct(product.id)}
              className="absolute top-2 right-2"
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
        </div>
      ))}
      <Button 
        variant="outlined" 
        color="primary" 
        onClick={addGenericProduct}
        startIcon={<Add />}
        className="mt-4"
      >
        Add Product
      </Button>
    </div>
  );
};

export default GenericProductForm;

