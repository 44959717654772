import type React from "react"
import { useState, useEffect, useMemo } from "react"
import {
  TextField,
  MenuItem,
  CircularProgress,
  Grid,
  InputAdornment,
  Autocomplete,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import type { Customer, ProductSupplier, PaymentAccount } from "../../types/invoice"
import { fetchCustomers, fetchProductSuppliers, fetchPaymentAccounts } from "../../utils/api"
import { useFormContext } from "../../contexts/FormContext"
import { filterAndPaginateCustomers } from "../../utils/customerUtils"
import { PaymentsForm } from "./PaymentsForm"
import moment, { Moment } from "moment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

export const InvoiceForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext()
  const [customers, setCustomers] = useState<Customer[]>([])
  const [productSuppliers, setProductSuppliers] = useState<ProductSupplier[]>([])
  const [paymentAccounts, setPaymentAccounts] = useState<PaymentAccount[]>([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const [searchTerm, setSearchTerm] = useState("")
  const [page, setPage] = useState(1)

  const PRODUCT_TYPES = ["FLIGHT", "RENTALCAR", "HOTEL", "GENERIC", "TRANSFER"] as const
  type ProductType = (typeof PRODUCT_TYPES)[number]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)
        const [customersData, suppliersData, accountsData] = await Promise.all([
          fetchCustomers(),
          fetchProductSuppliers(),
          fetchPaymentAccounts(),
        ])
        setCustomers(customersData)
        setProductSuppliers(suppliersData)
        setPaymentAccounts(accountsData)
        setError(null)
      } catch (error) {
        console.error("Error fetching data:", error)
        setError("Failed to load data. Please try again later.")
      } finally {
        setLoading(false)
      }
    }

    fetchData()
  }, [])

  const { filteredCustomers, hasMore } = useMemo(
    () => filterAndPaginateCustomers(customers, searchTerm, page),
    [customers, searchTerm, page],
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    updateFormData({
      invoiceDetails: {
        ...formData.invoiceDetails,
        [name]: name === "totalAmount" ? Number.parseFloat(value) || 0 : value,
      },
    })
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress />
      </div>
    )
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-red-500">{error}</p>
      </div>
    )
  }

  const handleProductTypesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    updateFormData({
      invoiceDetails: {
        ...formData.invoiceDetails,
        productTypes: event.target.value as ProductType[],
      },
    })
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="col-span-1 md:col-span-2">
          <Autocomplete
            id="customer-select"
            options={filteredCustomers}
            getOptionLabel={(option) => option.text}
            value={customers.find((c) => c.id === formData.invoiceDetails.customerId) || null}
            onChange={(event, newValue) => {
              updateFormData({
                invoiceDetails: {
                  ...formData.invoiceDetails,
                  customerId: newValue ? newValue.id : "",
                  customerName: newValue ? newValue.text : "",
                },
              })
            }}
            onInputChange={(event, newInputValue) => {
              setSearchTerm(newInputValue)
              setPage(1)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }}
              />
            )}
            ListboxProps={{
              onScroll: (event) => {
                const listboxNode = event.currentTarget
                if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                  if (hasMore) {
                    setPage((prevPage) => prevPage + 1)
                  }
                }
              },
            }}
          />
        </div>
        <div>
          <TextField
            select
            name="productSupplierId"
            label="Product Supplier"
            value={formData.invoiceDetails.productSupplierId}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          >
            {productSuppliers.map((supplier) => (
              <MenuItem key={supplier.id} value={supplier.id}>
                {supplier.text}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            name="productRefId"
            label="Booking / PNR"
            value={formData.invoiceDetails.productRefId}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          />
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="col-span-1 md:col-span-2">
          <TextField
            select
            name="productTypes"
            label="Product Types"
            value={formData.invoiceDetails.productTypes}
            onChange={handleProductTypesChange}
            variant="outlined"
            fullWidth
            SelectProps={{
              multiple: true,
              renderValue: (selected) => (selected as ProductType[]).join(", "),
            }}
          >
            {PRODUCT_TYPES.map((type) => (
              <MenuItem key={type} value={type}>
                <ListItemIcon>
                  <Checkbox checked={formData.invoiceDetails.productTypes.indexOf(type) > -1} />
                </ListItemIcon>
                <ListItemText primary={type.charAt(0) + type.slice(1).toLowerCase()} />
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div>
          <TextField
            select
            name="invoiceType"
            label="Invoice Type"
            value={formData.invoiceDetails.invoiceType}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="regular">Regular</MenuItem>
            <MenuItem value="refund">Refund</MenuItem>
          </TextField>
        </div>
        <div>
          <TextField
            select
            name="invoiceStatus"
            label="Invoice Status"
            value={formData.invoiceDetails.invoiceStatus}
            onChange={handleInputChange}
            variant="outlined"
            fullWidth
          >
            <MenuItem value="DRAFT">DRAFT</MenuItem>
            <MenuItem value="INVOICED">INVOICED</MenuItem>
          </TextField>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div>
        <Grid item xs={12} sm={3}>
            <DatePicker
              value={moment(formData.invoiceDetails.invoiceDueDate)} 
              onChange={(date: Moment | null) => 
                updateFormData({
                  invoiceDetails: {
                    ...formData.invoiceDetails,
                    invoiceDueDate: date ? moment(date) : null,
                  },
                })
              }
              format="DD.MM.YYYY"
              slotProps={{
                textField: {
                  fullWidth: true,
                  variant: "outlined",
                },
              }}
              minDate={moment().subtract(5,"year")}
              maxDate={moment().add(5, "year")}
            />
          </Grid>
        </div>
      </div>

      <div>
        <PaymentsForm paymentAccounts={paymentAccounts} />
      </div>
    </div>
  )
}

